import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import parameters from './parameters/reducer';

const reducers = combineReducers({
  menu,
  settings,
  parameters
});

export default reducers;