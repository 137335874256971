import { SET_USER_FLOW } from '../actions';
import { GET_USER_FLOW } from '../actions';
import { SET_LOGGED_IN } from '../actions';
import { GET_LOGGED_IN } from '../actions';
import { SET_LOG_OUT } from '../actions';
import { GET_LOG_OUT } from '../actions';
import { SET_FIREBASE_APP } from '../actions';
import { GET_FIREBASE_APP } from '../actions';
import { SET_ACCOUNT_TYPE } from '../actions';
import { SET_USER_EMAIL } from '../actions';
import { SET_LOGGED_USER } from '../actions';
import { LOAD_COMPANIES } from '../actions';
import { GET_SU_EMAIL } from '../actions';
import { LOAD_PROJECTS } from '../actions';
import { LOAD_SUBPROJECTS } from '../actions';
import { GET_CL_EMAIL } from '../actions';
import { AVAILABLE_CLIENTS } from '../actions';
import { AVAILABLE_PROJECTS } from '../actions';
import { AVAILABLE_SUBPROJECTS } from '../actions';
import { AVAILABLE_CHECKLISTS } from '../actions';

const initialState = {
    userFlow: "signIn",
    loggedIn: false,
    logOut: false,
    firebaseApp: false,
    accountType: "",
    userEmail: "",
    loggedUser: "",
    companies: "",
    supplierEmail: "",
    projects: [],
    subprojects: [],
    clientEmail: "",
    clientsAvailable: false,
    projectsAvailable: false,
    subprojectsAvailable: false,
    checklistsAvailable: false,
};

function paramReducer(state = initialState, action) {

    switch (action.type) {
        case SET_LOGGED_IN:
            return Object.assign({}, state, action.payload);
        case GET_LOGGED_IN:
            return Object.assign({}, state, action.payload);
        case SET_USER_FLOW:
            return Object.assign({}, state, action.payload);
        case GET_USER_FLOW:
            return Object.assign({}, state, action.payload);
        case SET_LOG_OUT:
            return Object.assign({}, state, action.payload);
        case GET_LOG_OUT:
        case SET_FIREBASE_APP:
            return Object.assign({}, state, action.payload);
        case GET_FIREBASE_APP:
            return Object.assign({}, state, action.payload);
        case SET_ACCOUNT_TYPE:
            return Object.assign({}, state, action.payload);
        case SET_USER_EMAIL:
            return Object.assign({}, state, action.payload);
        case SET_LOGGED_USER:
            return Object.assign({}, state, action.payload);
        case LOAD_COMPANIES:
            return Object.assign({}, state, action.payload);
        case GET_SU_EMAIL:
            return Object.assign({}, state, action.payload);
        case LOAD_PROJECTS:
            return Object.assign({}, state, action.payload);
        case LOAD_SUBPROJECTS:
            return Object.assign({}, state, action.payload);
        case GET_CL_EMAIL:
            return Object.assign({}, state, action.payload);
        case AVAILABLE_CLIENTS:
            return Object.assign({}, state, action.payload);
        case AVAILABLE_PROJECTS:
            return Object.assign({}, state, action.payload);
        case AVAILABLE_SUBPROJECTS:
            return Object.assign({}, state, action.payload);
        case AVAILABLE_CHECKLISTS:
            return Object.assign({}, state, action.payload);
        default:
            break;
    }

    return state;
}

export default paramReducer;