/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* PARAMETERS */
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_LOGGED_USER = "SET_LOGGED_USER";
export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const GET_SU_EMAIL = "GET_SU_EMAIL";
export const LOAD_PROJECTS = "LOAD_PROJECTS";
export const LOAD_SUBPROJECTS = "LOAD_SUBPROJECTS";
export const GET_CL_EMAIL = "GET_CL_EMAIL";
export const AVAILABLE_CLIENTS = "AVAILABLE_CLIENTS";
export const AVAILABLE_PROJECTS = "AVAILABLE_PROJECTS";
export const AVAILABLE_SUBPROJECTS = "AVAILABLE_SUBPROJECTS";
export const AVAILABLE_CHECKLISTS = "AVAILABLE_CHECKLISTS";

/* USER FLOW */
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const GET_LOGGED_IN = "GET_LOGGED_IN";
export const SET_LOG_OUT = "SET_LOG_OUT";
export const GET_LOG_OUT = "GET_LOG_OUT";
export const SET_USER_FLOW = "SET_USER_FLOW";
export const GET_USER_FLOW = "GET_USER_FLOW";

/* FIREBASE APP INSTANCE */

export const SET_FIREBASE_APP = "SET_FIREBASE_APP";
export const GET_FIREBASE_APP = "GET_FIREBASE_APP";

export * from "./menu/actions";
export * from "./settings/actions";
